<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height">
              <b-col cols="12">
                <tab-position :tabs="tabs" :name="'Campaign Name'" />
              </b-col>
            </b-row> -->
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/pincodes`"
                title="Area"
                :columns="columns"
                routerpath="/master/crm/addarea"
                :formOptions="formOptions"
                :newPage="true"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
                :viewFields="viewFields"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios'
import TabPosition from '../../../../components/TabView.vue'
import GoodTableColumnSearch from '../../../../components/GoodTableColumnSearch.vue'
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  components: {
    GoodTableColumnSearch,
    TabPosition
  },
  data () {
    return {
      edit: '',
      add: '',
      canDelete: '',
      viewFields:[
        {
          label: 'Pincode',
          field: 'Pincode',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Area',
          field: 'PostOfficeName',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'City',
          field: 'City',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'District',
          field: 'District',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'State',
          field: 'State',
          type: 'text',
          class: "col-md-4",
        },
      ],

      // tabs: [
      //   { name: 'Lead Source', route: '/master/crm/leadsource', icon: 'CompassIcon' },
      //   { name: 'Lead Stage', route: '/master/crm/leadstage', icon: 'CommandIcon' },
      //   { name: 'Campaign Name', route: '/master/crm/campaign', icon: 'MinimizeIcon' },
      //   { name: 'Reason', route: '/master/crm/reason', icon: 'ApertureIcon' },
      //   { name: 'Channel Partner', route: '/master/crm/channelpartner', icon: 'UsersIcon' },
      //   { name: 'Franchise', route: '/master/crm/franchise', icon: 'FramerIcon' },
      //   { name: 'Project', route: '/master/crm/project', icon: 'PackageIcon' },
      //   { name: 'Plot', route: '/master/crm/plot', icon: 'MapIcon' },
      //   { name: 'Land', route: '/master/crm/land', icon: 'SlackIcon' },
      //   { name: 'Commission', route: '/master/crm/commission', icon: 'GitCommitIcon' },
      //   { name: 'Project Mapping', route: '/master/crm/projectmapping', icon: 'MapPinIcon' }
      // ],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        formTitle: `${this.$route.params.id ? 'Edit Area' : 'Add Area'}`,
        submitRouterPath: '/master/crm/pincodes',
        gridForm: true,
        method: 'post',
        action: 'add',
        getEditValue: `${baseApi}/getpincodesById`,
        url: `${baseApi}/pincodes`,
        inputFields: [
          {
            name: 'branch',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Campaign Name',
            name: 'name',
            value: '',
            type: 'text',
            class: 'col-md-12',
            placeholder: 'Enter Campaign Name',
            required: true
          }
        ]
      },

      columns: [
        {
          label: 'Pincode',
          field: 'Pincode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Pincode'
          }
        },
        {
          label: 'Area',
          field: 'PostOfficeName',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Area'
          }
        },
        {
          label: 'City',
          field: 'City',
          filterOptions: {
            enabled: true,
            placeholder: 'Search City'
          }
        },
        {
          label: 'District',
          field: 'District',
          filterOptions: {
            enabled: true,
            placeholder: 'Search District'
          }
        },
        {
          label: 'State',
          field: 'State',
          filterOptions: {
            enabled: true,
            placeholder: 'Search State'
          }
        },
         {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: 'Enter Date',
          field: 'created_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date'
          }
        },
         {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: 'Modified Date',
          field: 'updated_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date'
          }
        },
        {
          label: 'Action',
          field: 'action'
        }
      ]
    }
  },
  mounted () {
    const userData = JSON.parse(localStorage.getItem('UserRights'))
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      userData.map((item) => {
        if (item.modulename == 'Area') {
          this.edit = item.edit
          this.add = item.add
          this.canDelete = item.delete
          if (item.edit !== 1 && item.add !== 1 && item.delete !== 1 && item.view !== 1) {
            this.$router.push('/')
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
